import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
import { Bolder, Header1, Paragraph } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import IconHome from '@/components/IconHome/IconHome'
import IconPhone from '@/components/IconPhone/IconPhone'
import IconEmail from '@/components/IconEmail/IconEmail'
import { ContactEmail, ContactPhone } from '@/constants/constants'
// @ts-ignore
import Image1 from '@/assets/images/kontakt.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 0 0 30px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 991px) {
    display: block;
  }

  img {
    max-width: 100% !important;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

const Description = styled.div`
  padding-right: 120px;

  @media (max-width: 1199px) {
    padding-right: 40px;
  }

  @media (max-width: 991px) {
    text-align: center;
    padding-right: 0;
  }
`

const ContactInfo = styled.div`
  display: grid;
  grid-row-gap: 55px;
  padding: 55px 30px;
  border: 5px solid ${Color.Turquoise_1};
  border-radius: 20px;

  @media (max-width: 991px) {
    border: none;
    grid-row-gap: 0;
    padding: 0 0 30px;
  }
`

const ContactInfoItem = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 20px;

  a {
    color: ${Color.Black};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 991px) {
    display: block;
  }
`

const Head = () => (
  <>
    <title>
      Pet School - Szkolenia i terapia behawioralna zwierząt | Kontakt
    </title>
  </>
)

const ContactPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <Grid>
              <Description>
                <Header1 marginBottom={50}>
                  <Bolder>Skontaktuj się</Bolder>
                  <br />
                  ze mną
                </Header1>

                <ContactInfo>
                  <ContactInfoItem>
                    <IconHome fill={Color.Turquoise_1} width={50} />
                    <Paragraph>Wrocław</Paragraph>
                  </ContactInfoItem>

                  <ContactInfoItem>
                    <IconPhone fill={Color.Turquoise_1} width={50} />
                    <Paragraph>
                      <a href={ContactPhone.value}>{ContactPhone.label}</a>
                    </Paragraph>
                  </ContactInfoItem>

                  <ContactInfoItem>
                    <IconEmail fill={Color.Turquoise_1} width={50} />
                    <Paragraph>
                      <a href={ContactEmail.value}>{ContactEmail.label}</a>
                    </Paragraph>
                  </ContactInfoItem>
                </ContactInfo>
              </Description>

              <img alt="Pet School - kontakt" src={Image1} />
            </Grid>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default ContactPage
